 <template>
	<el-dialog title="调度分配" top="1vh" width="80%" :modal="true" :visible.sync="is_show_in_page">
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="网点名称">
				<el-input class="el_input" v-model="form.name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="卸货地区">
				<el-cascader class="el_input" v-model="form.city" placeholder="请选择车型" :options="cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="审核状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="正常" value="1"></el-option>
					<el-option label="停用" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="网点编号/添加时间" width="190">
					<template slot-scope="scope">	
						<div>编号:{{scope.row.outlets_num}}</div>
						<div>时间:{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="网点名称">
					<template slot-scope="scope">
						{{scope.row.name}}
					</template>
				</el-table-column>
				<el-table-column label="网点地址">
					<template slot-scope="scope">
						<div class="tab_line_item">(省/市/区) {{scope.row.prov}} / {{scope.row.city}} / {{scope.row.county}}</div>
						<div class="tab_line_item">(详细地址) {{scope.row.addr}}</div>
					</template>
				</el-table-column>
				<el-table-column label="状态" prop="status_text"></el-table-column>
				<el-table-column label="备注" prop="mark"></el-table-column>
				
				<!-- 操作行 -->
				<el-table-column label="操作" width="190">
					<template slot-scope="scope">
						<el-button @click="open_edit_view(scope.row)" size="mini" type="text">修改</el-button>
						<el-button @click="del(scope.row)" size="mini" type="text">删除</el-button>
						<el-button @click="choice(scope.row)" size="mini" type="text">选择</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 添加弹出层 -->
		<outletsAdd :is_show="add.is_show" @sub="add_sub"></outletsAdd>

		<!-- 修改弹出层 -->
		<outletsEdit :is_show="edit.is_show" :old_data="edit.old_data" @sub="edit_sub"></outletsEdit>

	</div>
	</el-dialog>
</template>

<script>
	import outletsAdd from '../outlets/add.vue';
	import outletsEdit from '../outlets/edit.vue';
	import {regionDataPlus,CodeToText} from 'element-china-area-data'
	export default {
		components:{
			outletsAdd,
			outletsEdit
		},
		props:{
			old_data:Object,
			is_show:Number,
		},
		data() {
			return {
				
				//省市县基础数据
				cities:regionDataPlus,
				is_show_in_page:false,
				//搜索条件
				form: {
					name:'',//网点名称
					city:null,//网点省市县
					status:'',//状态
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//添加界面
				add:{

					//是否显示
					is_show:0
				},

				edit:{

					//是否显示
					is_show:0,

					//旧数据
					old_data:{}
				},
			}
		},
	    watch:{
			is_show(new_data){
				if(new_data){
					this.get_page_data()
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//删除
			del(item){

				//询问
				this.$my.other.confirm({
					content:"点击'确定'删除路线",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_business_outlets',
								ctr:'outlets_del_admin',
								outlets_num:item.outlets_num
							},
							callback:(data)=>{
								
								//读取页面数据
								this.get_page_data()
							}
						})
					}
				});
			},
			
			//前往添加页面
			open_add_view(){
				
				//打开界面
				this.add.is_show++;
			},
			add_sub(form){//添加
				
				//报告结果
				this.$my.other.msg({
					type:'success',
					str:"添加成功"
				});

				//关闭页面
				this.add.is_show=0
				
				//刷新页面
				this.get_page_data()
			},

			//打开客户修改界面
			open_edit_view(item){

				//置入数据
				this.edit.old_data=item

				//打开界面
				this.edit.is_show++;
			},
			edit_sub(form){
				
				//报告结果
				this.$my.other.msg({
					type:'success',
					str:"修改成功"
				});

				//关闭页面
				this.edit.is_show=0

				//读取页面数据
				this.get_page_data()
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					name:'',//网点名称
					city:null,//网点省市县
					status:'',//状态
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){
				this.is_show_in_page = true
				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_business_outlets',
						ctr:'outlets_list_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							
							//实名状态
							switch(item.status){
								case '1':item.status_text='正常';break;
								case '2':item.status_text='停用';break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>